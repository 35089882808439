<template>
  <div class="set_repeat">
    <div class="set_repeat_title">
      <span>一级审核：</span>
      <textSwitch
        v-model="ruleForm.oneStep"
        :width="50"
        active-text="停用"
        :active-value="true"
        :inactive-value="false"
        inactive-text="启用"
      />
    </div>
    <div class="set_repeat_content el-form demo-form-inline el-form--inline">
      <div class="el-form-item">
        <div class="el-form-item__label">审核环节名称：</div>
        <div class="el-form-item__content">
          <el-input
            v-model="ruleForm.oneStepName"
            style="width: 100%; max-width: 280px"
            placeholder="请输入内容"
          ></el-input>
        </div>
      </div>
      <div class="el-form-item">
        <div class="el-form-item__label">审核人：</div>
        <div class="el-form-item__content">部门审核人</div>
      </div>
      <div class="el-form-item"></div>
    </div>
    <div class="set_repeat_title">
      <span>二级审核：</span>
      <textSwitch
        v-model="ruleForm.twoStep"
        :width="50"
        :active-value="true"
        :inactive-value="false"
        active-text="停用"
        inactive-text="启用"
      />
    </div>
    <div class="set_repeat_content el-form demo-form-inline el-form--inline">
      <div class="el-form-item">
        <div class="el-form-item__label">审核环节名称：</div>
        <div class="el-form-item__content">
          <el-input
            v-model="ruleForm.twoStepName"
            style="width: 100%; max-width: 280px"
            placeholder="请输入内容"
          ></el-input>
        </div>
      </div>
      <div class="el-form-item">
        <div class="el-form-item__label">审批部门：</div>
        <div class="el-form-item__content">
          <el-select
            v-model="ruleForm.twoStepDeptName"
            filterable
            remotepopper-class="staff-demo-ruleForm"
            placeholder="请选择所属部门"
            remote
            ref="bm-select"
            clearable
            @visible-change="visibleChange"
            :remote-method="remoteMethod"
          >
            <el-option label value>
              <div @click.stop="">
                <el-tree
                  :data="deptOptions"
                  check-strictly
                  show-checkbox
                  ref="deptTree"
                  default-expand-all
                  node-key="id"
                  :props="defaultProps"
                  @check-change="handleNodeClick"
                  :filter-node-method="filterNode"
                ></el-tree>
              </div>
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="el-form-item">
        <div class="el-form-item__label">审核人：</div>
        <div class="el-form-item__content">
          <el-select
            v-model="ruleForm.twoStepApproverId"
            style="width: 100%; max-width: 280px"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.userId"
              :label="item.name"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="set_repeat_title">
      <span>其他设置：</span>
    </div>
    <div
      class="set_repeat_content el-form demo-form-inline el-form--inline"
      style="display: flex; flex-direction: column"
    >
      <div class="el-form-item">
        <div class="el-form-item__label">禁用账号：</div>
        <div class="el-form-item__content">
          <div>
            <el-checkbox
              v-model="ruleForm.disableAccount"
              :true-label="true"
              :false-label="false"
              >不触发审核</el-checkbox
            >
          </div>
        </div>
      </div>
      <div class="el-form-item">
        <div class="el-form-item__label">删除账号：</div>
        <div class="el-form-item__content">
          <div>
            <el-checkbox
              v-model="ruleForm.deleteAccount"
              :true-label="true"
              :false-label="false"
              >不触发审核</el-checkbox
            >
          </div>
        </div>
      </div>
    </div>
    <div class="set_repeat_title">
      <span>通知设置：</span>
    </div>
    <div class="set_repeat_content el-form demo-form-inline el-form--inline">
      <div class="el-form-item">
        <div class="el-form-item__label">通知方式：</div>
        <div class="el-form-item__content">
          <div>
            <el-checkbox
              v-model="ruleForm.miniAppNotfiy"
              :true-label="0"
              :false-label="1"
              >小程序站内消息</el-checkbox
            >
          </div>
          <el-checkbox
            v-model="ruleForm.wechatOfficialNotfiy"
            :true-label="0"
            :false-label="1"
            >公众号</el-checkbox
          >
        </div>
      </div>
      <div class="el-form-item"></div>
      <div class="el-form-item"></div>
    </div>
    <div class="set_repeat_button">
      <el-button>返回</el-button>
      <el-button type="primary" icon="el-icon-refresh" plain @click="getDetail"
        >重置</el-button
      >
      <el-button type="primary" icon="el-icon-check" @click="handleSubmit"
        >确定</el-button
      >
    </div>
  </div>
</template>

<script>
import textSwitch from "@/components/textSwitch/index.vue";
import {
  getOrganizationTree,
  getStaffByDepartById,
  processSettingSaveOrUpdate,
  processSettingDetail,
} from "@/api/SystemData";
export default {
  components: { textSwitch },
  data() {
    return {
      value: true,
      checked: "1",
      options: [],
      ruleForm: {
        disableAccount: true,
        deleteAccount: true,
        miniAppNotfiy: 0,
        oneStep: true,
        oneStepApprover: "",
        oneStepName: "",
        twoStep: true,
        twoStepApprover: "",
        twoStepApproverId: "",
        twoStepDept: 0,
        twoStepDeptName: "",
        twoStepName: "",
        wechatOfficialNotfiy: 0,
      },
      deptOptions: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      isAlreadyExist: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      getOrganizationTree({ tenantId: this.$store.state.tenantId }).then(
        (res) => {
          this.deptOptions = res.data;
          //   if (ids) {
          //     this.$nextTick(() => {
          //       this.$refs.deptTree.setCheckedKeys(ids);
          //       this.changeDeptId();
          //     });
          //   }
          this.getDetail();
        }
      );
    },
    getDetail() {
      processSettingDetail().then((res) => {
        if (res.data.id) {
          this.ruleForm = res.data;
          if (res.data.twoStepDept) {
            this.handleNodeClick(
              { id: res.data.twoStepDept, title: res.data.twoStepDeptName },
              true
            );
          }
        }
      });
    },
    handleNodeClick(data, checked) {
      if (checked) {
        if (this.ruleForm.twoStepDeptName) {
          this.isAlreadyExist = true;
        }
        this.$refs.deptTree.setCheckedNodes([data]);
        this.ruleForm.twoStepDept = data.id;
        this.ruleForm.twoStepDeptName = data.title;
        getStaffByDepartById({ deptId: data.id, current: 1, size: 999 }).then(
          (res) => {
            this.options = res.data.records;
            if (
              !res.data.records.some(
                (val) => val.userId == this.ruleForm.twoStepApproverId
              )
            ) {
              this.ruleForm.twoStepApproverId = "";
            }
          }
        );
      } else if (!this.isAlreadyExist) {
        this.isAlreadyExist = false;
        this.ruleForm.twoStepDept = "";
        this.ruleForm.twoStepDeptName = "";
      }
      this.$refs["bm-select"].blur();
    },
    remoteMethod(e) {
      this.$refs.deptTree.filter(e);
    },
    visibleChange(v) {
      if (v) {
        this.$refs.deptTree.filter("");
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.title.indexOf(value) !== -1;
    },
    handleSubmit() {
      if (!this.ruleForm.oneStep) {
        if (!this.ruleForm.oneStepName) {
          this.$message.error("请输入一级审核环节名称");
          return false;
        }
      }
      if (!this.ruleForm.twoStep) {
        if (!this.ruleForm.twoStepName) {
          this.$message.error("请输入二级审核环节名称");
          return false;
        }
        if (!this.ruleForm.twoStepDeptName) {
          this.$message.error("请输入二级审核部门");
          return false;
        }
        if (!this.ruleForm.twoStepApproverId) {
          this.$message.error("请输入二级审核人");
          return false;
        }
      }
      processSettingSaveOrUpdate(this.ruleForm).then((res) => {
        this.getDetail();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select-dropdown__item {
  height: auto;
  background-color: #fff !important;
}
.set_repeat {
  min-height: 100%;
  padding: 24px;
  background-color: #fff;
  &_title {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    padding-left: 14px;
    margin-bottom: 24px;
  }
  &_title::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 6px;
    height: 16px;
    background: #4278c9;
    border-radius: 3px;
  }
  &_button {
    margin-top: 80px;
  }
  .el-form-item {
    display: flex;
    max-width: 500px;
    flex: 1;
    &__label {
      padding-left: 14px;
    }
    &__content {
      flex: 1;
    }
  }
  .set_repeat_content {
    display: flex;
  }
}
</style>
